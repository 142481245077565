<template>
  <div class="goods-page">
    <v-header/>
    <van-pull-refresh v-model="pageData.refreshing" @refresh="loadPageData(1,true,200)">
      <van-list v-show="pageData.total>0"
                offset="50"
                :immediate-check="true"
                :finished="!pageData.hasMore"
                finished-text="灵感到底了~"
                v-model="pageData.loading"
                @load="loadPageData(pageData.page+1,false)">
        <div v-for="item in pageData.rows" class="order-item" :key="item.id" @click="$router.push(`/shop/order/${item.orderNo}`)">
          <div class="order-item-header">
            <div>{{ item.orderNo }}</div>
            <div>{{ item.gmtCreate }}</div>
          </div>
          <div class="order-item-info">
            <div class="order-item-info-left">
              <img class="order-item-cover" :src="item.goodsCover" alt="">
              <div class="order-item-info-main">
                <div class="order-item-goods-title">{{ item.goodsTitle }}</div>
                <div class="order-item-goods-desc">{{ item.goodsDesc }}</div>
              </div>
            </div>
          </div>
          <div class="order-item-status">
            <div class="status-str">{{getStatusStr(item.status)}}</div>
            <div class="red">¥{{ item.actualPrice || item.totalPrice }}</div>
          </div>
        </div>
      </van-list>
      <div class="scroll-empty" v-show="pageData.total<=0">
        <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150" style="margin-bottom: 20px">
        <div>噢！这可能有点儿空 (⊙v⊙)</div>
      </div>
    </van-pull-refresh>
  </div>
</template>


------------------ script ------------------
<script>
import {sleep} from "../../config/util";
import {goodsV2Api} from "../../api/v2/goodsV2Api";
import GoodsCard from "../../components/shop/GoodsCard";
import {orderV2Api} from "../../api/v2/orderV2Api";

export default {
  components: {GoodsCard},
  data() {
    return {
      pageData: {
        refreshing: false,
        total: 0,
        page: 1,
        size: 10,
        rows: [],
        hasMore: false,
        loading: false
      }
    }
  },

  created() {
    this.loadPageData(1, true, 200);
  },


  methods: {
    // 加载数据
    async loadPageData(page, clearRecords = false, minLoadingMs) {
      try {
        this.$loading('努力追溯中...');
        await sleep(minLoadingMs || 0);
        const params = {
          page,
          size: this.pageData.size,
        };

        const res = await orderV2Api.searchMy(params);
        this.pageData.page = res.page;
        this.pageData.size = res.size;
        this.pageData.total = res.total;
        if (clearRecords) {
          this.pageData.rows = res.rows;
        } else {
          this.pageData.rows = [...this.pageData.rows, ...res.rows];
        }
      } finally {
        this.pageData.hasMore = this.pageData.page * this.pageData.size < this.pageData.total;
        this.pageData.refreshing = false;
        this.pageData.loading = false
        this.$loading.close();
      }
    },

    // 状态文本
    getStatusStr(status){
      switch (status) {
        case 1:
          return '未支付'
        case 2:
          return '待发货'
        case 3:
          return '已发货'
        case 4:
          return '已完成'
        case 10:
          return '退款中'
        case 11:
          return '已取消'
      }
    }

  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.goods-page {
  padding: 40px 0 0 0;
  background: #f3f5f7;
  min-height: calc(100vh - 40px);
}

.order-item {
  margin: 16px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.order-item-info {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 16px;
}

.order-item-cover{
  border-radius: 4px;
  border: 1px dashed #e9e9e9;
  margin-right: 16px;
}

.order-item-info-left{
  display: flex;
  align-items: center;
}

.order-item-header {
  color: #999;
  font-size: 12px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-item-info {
  display: flex;
  align-items: center;
}

.order-item-cover {
  height: 100px;
  width: 100px;
}

.order-item-goods-desc{
  font-size: 12px;
  color: #999;
  margin-top: 8px;
}

.order-item-status{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-top: 1px solid #e9e9e9;
}

.status-str{
  font-size: 12px;
  color: #999;
}


.scroll-empty{
  height: 85vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
