<template>
  <router-link :to="`/shop/goods/${item.id}`" class="goods-card">
    <div class="cover">
      <van-image lazy :src="ossResize(item.cover,200,200)">
        <img class="img" src="https://cdn.001ppt.cn/pc2/static/imgs/jpg/plan-cover-placeholder.png" alt=""
             slot="placeholder">
      </van-image>
    </div>
    <div class="price">¥{{ item.price }}</div>
    <div class="title">{{ item.title }}</div>
  </router-link>
</template>


------------------ script ------------------
<script>
export default {
  props: {
    item: {
      type: Object
    }
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>

.goods-card {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow .5s;
  display: block;
  color: #444;
  margin: 16px 16px 0 16px;

  &:hover {
    box-shadow: #ccc 3px 5px 20px;
  }

  .cover {
    position: relative;
    overflow: hidden;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .el-image {
    position: absolute;
    left: 0;
    right: 0;
  }

  .price {
    color: #EE4A50;
    font-size: 18px;
    padding: 16px 8px;
    font-weight: 900;
    height: 16px;
  }

  .title {
    padding: 0 8px;
    font-size: 14px;
    line-height: 24px;
    height: 48px;
  }
}

</style>
